<template>
  <div class="center-g">
    <br />
    <br />
    <img src="@/assets/logo.png" class="imgwrap-g" alt />
    <h1>十万八后台管理</h1>
    <el-form
      :model="loginForm"
      :rules="rules"
      ref="loginForm"
      label-width="100px"
      class="inputbox"
      label-position="left"
      hide-required-asterisk
    >
      <el-form-item label="用户名" prop="username">
        <el-input v-model="loginForm.username" maxlength="15"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="loginForm.password" type="password" maxlength="15"></el-input>
      </el-form-item>
      <br />
      <el-button type="primary" @click="submitForm('loginForm')">登陆</el-button>
    </el-form>
  </div>
</template>

<script>
import { login } from "@/api/api";
export default {
  data() {
    var checkInput = (rule, value, callback) => {
      let pattern = /[0-9a-zA-Z]{6,15}/;
      var test = pattern.test(value);
      if (!value) {
        callback(new Error("不能为空"));
      } else if (!test) {
        callback(new Error("只能6~15位英文和数字"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: "",
        password: ""
      },
      rules: {
        username: [{ validator: checkInput, trigger: "blur" }],
        password: [{ validator: checkInput, trigger: "blur" }]
      }
    };
  },

  methods: {
    LoginIn() {
      const that = this;
      if (that.loginForm.password == "123456") {
        //初始密码登陆时，要求修改初始密码
        that.$alert("请立刻修改登陆密码", {
          type: "warning",
          callback: () => {
            that.$router.push({ name: "resetpsd" });
          }
        });
      } else {
        that.$router.push({ name: "authsights" });
      }
    },
    submitForm(loginForm) {
      const that = this;
      that.$refs[loginForm].validate(valid => {
        if (valid) {
          login(that.loginForm).then(res => {
            if (res.result) {
              sessionStorage.setItem("Authorization", res.data.Authorization);
              that.$store.dispatch("setAdmin", res.data.member);
              that.LoginIn();
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style scoped>
img {
  margin-top: 100px;
}
.inputbox {
  width: 400px;
  margin: 80px auto;
}
</style>
